<template>
	<div>

		<el-table :data="data" @current-change="handleCurrentChange" ref="singleTable" style="width: 700px;">
			<el-table-column width="40">
				<el-checkbox slot-scope="{row}" :value="row.id == chooseId" />
			</el-table-column>
		<!-- 	
			<el-table-column v-for="(colOpt,i) in columnOption" :label="$t(colOpt.title)" :key="i"
				:prop="`otherObj.${colOpt.prop}.value`" />
 -->
			<el-table-column
			 v-for="(colOpt,i) in columnOption" :key="i"
				  :label="$t(colOpt.title)"
				  >
				  <template slot-scope="{row}" >
					  <sapn v-if="typeof row.otherObj[`${colOpt.prop}`].value=='boolean' ">
						  {{ row.otherObj[`${colOpt.prop}`].value?'是':'否'}}
					  </sapn>
					  <span v-else>
						  {{ row.otherObj[`${colOpt.prop}`].value}}
					  </span>
				
					  {{ row.otherObj[`${colOpt.prop}`].unit}}
				  </template>
				</el-table-column>
			<el-table-column v-if="showSupplier" :label="$t('custom.page1.产线')" prop="supplierName" />
		</el-table>
		
		
		
		
	</div>

</template>

<script>
	export default {
		name: "TableSelct",
		data() {
			return {
				chooseId: null,
				data: [],




			}
		},
		props: {
			list: {
				type: Array,
				default: () => []
			},
			/**
			 * 格式 {title,prop}<br>
			 * title: 表头<br>
			 * prop: 显示的数据的属性名
			 */
			columnOption: {
				type: Array,
				default: () => []
			},
			showSupplier: {
				type: Boolean,
				default: false
			},
		},
		watch: {
			list: {
				handler(val) {
					if (Array.isArray(val)) {

						this.data = val;


					} else {

						this.data = [val]
					}
					
				},
				immediate: true
			},
		},
		methods: {
			handleCurrentChange(current, oldCurrent) {
				
				if (current) {
					this.chooseId = current.id
					this.$emit("select", current)
				}
			},
			clearChoose() {
				this.chooseId = null;
				this.$refs.singleTable.setCurrentRow();
			},
		},
	}
</script>

<style scoped lang='scss'>
	.a {}
</style>
